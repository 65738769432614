@font-face {
  font-family: 'eon_brix_sansblack';
  src: url('../fonts/eon_brixsans-black-webfont.eot');
  src: url('../fonts/eon_brixsans-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-black-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-black-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-black-webfont.svg#eon_brix_sansblack') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansblack_italic';
  src: url('../fonts/eon_brixsans-blackitalic-webfont.eot');
  src: url('../fonts/eon_brixsans-blackitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-blackitalic-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-blackitalic-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-blackitalic-webfont.svg#eon_brix_sansblack_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansbold';
  src: url('../fonts/eon_brixsans-bold-webfont.eot');
  src: url('../fonts/eon_brixsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-bold-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-bold-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-bold-webfont.svg#eon_brix_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansbold_italic';
  src: url('../fonts/eon_brixsans-bolditalic-webfont.eot');
  src: url('../fonts/eon_brixsans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-bolditalic-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-bolditalic-webfont.svg#eon_brix_sansbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansmedium';
  src: url('../fonts/eon_brixsans-medium-webfont.eot');
  src: url('../fonts/eon_brixsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-medium-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-medium-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-medium-webfont.svg#eon_brix_sansmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansmedium_italic';
  src: url('../fonts/eon_brixsans-mediumitalic-webfont.eot');
  src: url('../fonts/eon_brixsans-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-mediumitalic-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-mediumitalic-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-mediumitalic-webfont.svg#eon_brix_sansmedium_italic') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'eon_brix_sansregular';
  src: url('../fonts/eon_brixsans-regular-webfont.eot');
  src: url('../fonts/eon_brixsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-regular-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-regular-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-regular-webfont.svg#eon_brix_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'eon_brix_sansregular_italic';
  src: url('../fonts/eon_brixsans-regularitalic-webfont.eot');
  src: url('../fonts/eon_brixsans-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/eon_brixsans-regularitalic-webfont.woff2') format('woff2'),
       url('../fonts/eon_brixsans-regularitalic-webfont.woff') format('woff'),
       url('../fonts/eon_brixsans-regularitalic-webfont.svg#eon_brix_sansregular_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  font-family: 'eon_brix_sansregular';
}

h1, h2 {
  font-family: 'eon_brix_sansbold';
}

body::-webkit-scrollbar { 
  width: 0 !important; 
  height: 0 !important
}

.cityContainer {
  height: 100%;
  background-color: #8faeca;
  background-image: url("../img/eon-city.svg");
  background-size: auto 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;

}

.square {
  position: relative;
}

.square:first-child {
  margin-left: 4.9%
}

.square:last-child {
  margin-right: 5.5% 
}

.navContainer {
  position: fixed;
  width: 100%;
  bottom: 0px;

  padding: 20px 30px 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.navContainer_inner {
  background: #fff;
  padding: 0px 40px 12px 40px;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-top-right-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-topright: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.navContainer ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
}

.navContainer ul li {
  padding: 15px 40px 6px 40px;
  color: #aaaaaa;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  transition: color 2s;
  font-family: 'eon_brix_sansbold';
}

.navContainer ul li.current {
  color: #F39E14
}

.scrollBar {
  height: 4px;
  width: 100%;
  background-color: #aaaaaa;
  position: relative;
}

.scrollIndicator {
  width: 148px;
  height: 4px;
  background-color: #F39E14;
  left: 0px;
  position: absolute;
  transition: left 2s, width 2s;
}

#startOverlay, #dataOverlay {
  background-color: rgba(76,80,103,0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 130px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
}

#dataOverlay {
  display: none;
}

#dataOverlay.visible {
  display: flex;
}

.startDialog, .dataDialogs {
  background-color: #fff;
  text-align: center; 
  box-sizing: border-box;
  max-width: 1140px;
  width: 100%;
  margin: auto;
  border-radius: 6px;
  position: relative;
}

.startDialog {
  padding: 180px 100px;
}

.dataDialogs {
  padding: 100px 60px;
  width: 100%;
  max-width: 1140px;
  min-height: 600px;
}

.startDialog h1 {
  font-size: 64px;
  font-family: 'eon_brix_sansbold';
  color: #313039;
  margin: 8px 0px;
}

.startDialog p{
  font-size: 20px;
  color: #313039;
  margin: 8px 0px;
}

.startDialog button {
  font-size: 24px;
  background-color: #F39E14;
  border: none;
  color: #fff;
  padding: 16px 70px;
  border-radius: 6px;
  font-family: 'eon_brix_sansbold';
  margin-top: 50px;
}

.animationElement {
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

.animationElement .elementTooltip {
  position: absolute;
  background: #ffffff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  text-align: center;
  padding: 20px 60px 20px 30px;
  z-index: 3;
  font-size: 18px;
  font-family: 'eon_brix_sansbold';
  opacity: 0;
  transition: opacity 1s;
}

.animationElement .elementTooltip:after {
  content: "";
  position: absolute;
  right: 20px;
  display: block;
  background-image: url("../img/arrow_mouseoverLabel.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;
  top: 20px;
  height: 22px;
  width: 22px;
}

.animationElement:hover .elementTooltip {
  opacity: 1.0;
}

#animationElement_statue {
  left: 11%;
  top: 37%;
  width: 92%;
}

#animationElement_statue .elementTooltip {
  left: 1%;
  top: 62%;
}

#animationElement_growth {
  left: -2%;
  top: -13%;
  width: 82%;
}

#animationElement_growth .elementTooltip {
  left: 0%;
  top: 46%;
}

#animationElement_mall {
  left: -33%;
  top: -12%;
  width: 102%;
}

#animationElement_mall .elementTooltip {
  left: 40%;
  top: 22%;
}

#animationElement_park {
  left: -10%;
  top: 48%;
  width: 81%;
}

#animationElement_park .elementTooltip {
  left: 20%;
  top: 42%;
}

#animationElement_bank {
  left:37%;
  top: 22%;
  width: 71%;
}

#animationElement_bank .elementTooltip {
  left: 20%;
  top: 42%;
}

#animationElement_size {
  left:40%;
  top: 58%;
  width: 80%;
}

#animationElement_size .elementTooltip {
  left: 20%;
  top: 40%;
}

.dataDialog {
  position: relative;
  text-align: left;
  display: none;
}

.dataDialog.current {
  display: flex;
}

.selectContainer {
  width: 40%;
  height: 100%;
  position: relative;
}

.selectContainer h2 {
  font-size: 40px;
  color: #313039;
  margin: 0px;
  line-height: 40px;
}

.selectContainer h3 {
  font-size: 18px;
  color: #F39E14;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0px;
}

.selectContainer ul {
  list-style: none;
  margin: 20px 0px 0px 0px;
  padding: 20px 0px;
}

.selectContainer ul li {
  display: block;
  margin-bottom: 20px;
  padding: 20px 0px 20px 40px;
  border-radius: 6px;
  border: 2px solid #222222;
  color: #222222;
  opacity: 0.3;
  transition: color 1s, border 1s, opacity 1s, height 1s;
  cursor: pointer;
  position: relative;
  height: 59px;
  overflow: hidden;
  box-sizing: border-box;
}

.selectContainer ul li h5 {
  font-family: 'eon_brix_sansbold';
  font-size: 17px;
  margin: 0px;
}

.selectContainer ul li h6 {
  font-size: 12px;
  margin: 10px 0px;
}

.selectContainer ul li.current {
  border: 2px solid #F39E14;
  opacity: 1.0;
  height: 220px;
}

.selectContainer ul li.current h5 {
  color: #F39E14;
}

.selectContainer ul li:before {
  display: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 15px;
  top: 24px;
  left: 14px;
  background-image: url("../img/check.svg");
  opacity: 0;
  transition: opacity 1s;
}

.selectContainer ul li.current:before {
 opacity: 1.0;
}

.selectContainer ul li img {
  width: 100%;
}

.selectContainer .labelList {
  display:inline-flex;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  padding: 0px 0px;
  margin: 0px;
}

.selectContainer .regionList {
  display:inline-flex;
  padding: 0px 0px;
  margin: 0px;
  flex-wrap: wrap;
}

.selectContainer ul.labelList li {
  width: auto;
  border: none;
  height: auto;
  padding: 6px 16px;
  margin: 0px;
  font-size: 14px;
  border-radius: 20px;
}

.selectContainer ul.regionList li {
  width: auto;
  border: none;
  height: auto;
  padding: 6px 16px;
  margin: 0px;
  font-size: 14px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  opacity: 1.0;
  background: #F39E14;
  color: #fff;
  margin-right: 6px;
  min-width: 80px;
  text-align: center;
  margin-bottom: 4px;
}

.selectContainer ul.regionList li:nth-child(2) {
  background-color: #CC51A5;
}

.selectContainer ul.regionList li:nth-child(3) {
  background-color: #557DA6;
}

.selectContainer ul.regionList li.plusButton {
  background-color: #fff!important;
  color: #222;
}

.selectContainer ul.labelList li.current {
  background-color: #F39E14;
  color: #fff;
}

.selectContainer .labelList li:before, .selectContainer .regionList li:before {
  display: none;
}

.dataContainer {
  width: 55%;
  margin-left: 5%;
  height: 100%;
}

.dataContainer .dataElement {
  display: none;
  padding-top: 300px;
}

.dataContainer .dataElement.current {
  display: block;
}

.dataContainer {
  height: 260px;
  position: relative;
}

.canvasContainer {
  height: 260px;
  width: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}


.dataContainer .canvasContainer .half{
  width: 50%;
  float: left;
  padding: 0px 10px;
  box-sizing: border-box;
  overflow: auto;
}

.dataContainer .canvasContainer .full{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: none;
}

.dataContainer .canvasContainer h3 {
  margin: 0px;
}

.dataContainer .canvasContainer .selectorContainer {
  display: flex;
  justify-content: center;
}

.dataContainer .canvasContainer select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  padding-right: 20px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../img/arrow_dropdown.svg");
  background-position: 100% 50%;
  border: none;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  font-family: 'eon_brix_sansbold';
  outline: none;
}

.dataContainer .dataElement select::-ms-expand {
  display: none;
}

.dataContainer .dataElement h4 {
  font-size: 18px;
  margin: 40px 0px 0px 0px;
  line-height: 30px;
  font-family: 'eon_brix_sansbold';
}

.dataContainer .dataElement h5 {
  font-size: 22px;
  margin: 0px;
  text-align: center;
}

.dataContainer .dataElement p {
  font-size: 14px;
  line-height: 24px;
  margin: 6px 0px 0px 0px;
  
}



.dataContainer .dataElement .metaContainer {
  text-align: center;
  position: relative;
  height: 20px;
}

.legende {
  position: absolute;
  left: 0px;
  width: 400px;
  top: 300px;
  font-size: 13px;
}

.legende span {
  display: block;
  float: left;
}

.legende span.colorBlock {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.legende span.legendText {
  line-height: 14px;
  margin-right: 8px;
}

.dataContainer .dataElement .metaContainer .sources {
  float: right;
  cursor: pointer;
  width: 200px;
  text-align: center;
  font-size: 13px;
}

.dataContainer .dataElement .sourcesOverlay {
  background: #ffffff;
  border: 2px solid #F39E14;
  border-radius: 6px;
  width: 300px;
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  font-size: 13px;
  right: -50px;
  top: 30px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  display: none;
}

.dataContainer .dataElement .sourcesOverlay:before {
  content: "";
  width: 0; 
  height: 0; 
  position: absolute;
  top: -10px;
  left: calc(50% - 10px);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #F39E14;
}

.closeButton {
  width: 14px;
  height: 14px;
  background-image: url("../img/close.svg");
  background-size: cover;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.helpContainer {
  height: 100%;
  position: absolute;
  background-image: url("../img/eon-city-foreground.svg");
  left: 0px;
  top: 0px;
  background-size: auto 100%;
  z-index: 2;
  pointer-events: none;
}

.slideStatus {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 20px;
  width: 100%;
  left: 0px;
}

.slideStatus ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  list-style: none;
}

.slideStatus ul li {
  width: 10px;
  height: 10px;
  margin: 0px 5px;
  background-color: #D8D8D8;
  border-radius: 50% 50%;
}

.slideStatus ul li.active {
  background-color: #F39E14;
}


#regionOverlay {
  width: 800px;
  height: 370px;
  position: absolute;
  left: 30px;
  top: 40px;
  background: #fff;
  display: none;
  z-index: 1000;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  border: 2px solid #F39E14;
  box-sizing: border-box;
  padding: 40px;
}

#regionOverlay:before {
  content: "";
  position: absolute;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #F39E14;
  left: -10px;
  top: calc(50% - 10px);
}

#regionOverlay h3 {
  color: #222;
  text-transform: none;
}

#regionOverlay .regionSelectList {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

#regionOverlay .regionSelectList span {
  display: block;
  width: 33.33%;
  height: 20px;
  line-height: 20px;
  margin: 6px 0px;
  box-sizing: border-box;
  padding-left: 30px;
  position: relative;
  font-size: 14px;
}

#regionOverlay .regionSelectList span:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  border-radius: 50% 50%;
}

#regionOverlay .regionSelectList span.current:before {
  background-color: #F39E14;
  background-image: url("../img/close-white.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 40%;
}

#regionOverlay button {
  border-radius: none;
  border: none;
  background: #F39E14;
  color: #fff;
  padding: 10px 40px;
  font-size: 16px;
  cursor: pointer;
}

.dummyDialogImage {
  width: 100%
}

#prevButton { 
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 20px;
  background-image: url("../img/arrow-left.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

#nextButton { 
  width: 30px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 20px;
  background-image: url("../img/arrow-left.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: rotate(180deg)
}